import { useEffect, useState } from 'react';
import { ReactComponent as ProfileIcon } from '../../assets/icons/menus/profile.svg';
import { ReactComponent as EmailIcon } from '../../assets/icons/profile/email.svg';
import { ReactComponent as PhoneIcon } from '../../assets/icons/profile/phone.svg';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import styles from './styles.module.css';
import coverImage from './cover.png';
import { apiClient } from "../../api/apiClient";

export const ProfilePage = () => {
  const [userData, setUserData] = useState<{ bio: string; email?: string; firstName: string; phone?: string }>();

  useEffect(() => {
    const init = async () => {
      const res = await apiClient.get('/users-permissions/auth/verifyUser');
      setUserData(res?.data);
    };
    init();
  }, []);

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <div className={styles.profileHead}>
            <img src={coverImage} alt="" />
            <div className={styles.profileHeadRow}>
              <div className={styles.profileIcon}>
                <ProfileIcon />
              </div>
              <div className={styles.username}>{userData?.firstName}</div>
            </div>
          </div>
          <div className={styles.cards}>
            <div className={styles.leftColumn}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  About
                </div>
                <div className={styles.userDetails}>
                  <div className={styles.bio}>
                    {userData?.bio || <em>Bio is not available</em>}
                  </div>
                  <div className={styles.rowWithIcon}><PhoneIcon /> {userData?.phone ? <a href={`tel:${userData?.phone}`}>{userData?.phone}</a> : '—'}</div>
                  <div className={styles.rowWithIcon}><EmailIcon /> {userData?.email ? <a href={`mailto:${userData?.email}`}>{userData?.email}</a> : '—'}</div>
                </div>
              </div>
            </div>
            <div className={styles.rightColumn}>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  My Experience
                </div>
                <div className={styles.cardItemWithPadding}><em>Items not found</em></div>
              </div>
              <div className={styles.card}>
                <div className={styles.cardTitle}>
                  Trainings&Certificates
                </div>
                <div className={styles.cardItemWithPadding}><em>Items not found</em></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
