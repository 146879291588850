import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './styles.module.css';
import { apiClient } from "../../api/apiClient";
import { useAuthContext } from "../../contexts/AuthContext";

export const Home = () => {
  const { signOut } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    apiClient.get('/users-permissions/auth/verifyUser');
  }, []);

  const onLogout = () => {
    signOut();
    navigate('/sign-in/email');
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.visuals}>
            <h1 className={styles.welcomeTitle}>Welcome to our community</h1>
            <p className={styles.welcomeSubtitle}>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>
          <div className={styles.content}>
            <div className={styles.contentHead}>
              <a href="https://hyvery.com" className={styles.logo}>
                <Logo />
              </a>
              <a href="https://hyve.help.center.hyvery.com">Need Help?</a>
            </div>
            <form className={styles.form}>
              <h2>Welcome!</h2>
              <h2>You're authorized</h2>
              <div className={styles.controls}>
                <button className={styles.submitBtn} onClick={onLogout} type="button">
                  Log out
                </button>
              </div>
            </form>
            <div className={styles.contentLinks}>
              <a href="https://hyvery.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
              <a href="https://hyvery.com/terms-services" target="_blank" rel="noreferrer">Terms & Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
