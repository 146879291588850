import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATIONS } from "./query";
import { Loader } from "../../components/Loader";
import styles from './styles.module.css';

export const OrganizationSettingsPage = () => {
  const navigate = useNavigate();

  useQuery(GET_ORGANIZATIONS, {
    onCompleted: (resData) => {
      const orgId = resData?.organizations?.[0]?.data?.attributes.organizationID;
      if (orgId) {
        navigate(`/organization/${orgId}`);
      }
    },
  });

  return (
    <div className={styles.container}>
      <Loader isCentered />
    </div>
  );
};
