import { useRef, useState } from 'react';
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg';
import styles from './styles.module.css';
import { useHandleOutsideMouseDown } from "../../hooks/useHandleOutsideMouseDown";

interface ISelectProps {
  label: string;
  value: string;
  options: { key: string; label: string }[];
  onChange: (optionKey: string) => void;
  placeholder?: string;
}

export const Select = ({ label, value, options = [], onChange, placeholder = 'Select...' }: ISelectProps) => {
  const [showOptions, setShowOptions] = useState(false);
  const selectRef = useRef(null);
  const selectedLabel = options.find(option => option.key === value)?.label;
  useHandleOutsideMouseDown(selectRef, () => setShowOptions(false));
  return (
    <div className={styles.selectWrapper}>
      {label && <p className={styles.label}>{label}</p>}
      <div className={styles.select} onClick={() => setShowOptions(!showOptions)} ref={selectRef}>
        <p>{selectedLabel || placeholder}</p>
        <ArrowDownIcon className={showOptions ? styles.iconInverted : styles.icon} />
        {showOptions && (
          <div className={styles.optionsList}>
            {options.map((option) => (
              <div key={option.key} className={styles.option} onClick={() => onChange(option.key)}>{option.label}</div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
