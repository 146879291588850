import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { ViewDetailsButton } from '../../components/Table/ViewDetailsButton';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/menus/organizations.svg';
import styles from './styles.module.css';
import jsonData from './data.json';

export const OrganizationsPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const availableFilters = [
    { key: 'package', label: 'Package', values: [{ key: 'Claimed', label: 'Claimed' }, { key: 'Unclaimed', label: 'Unclaimed' }, { key: 'Internal', label: 'Internal' }] },
    { key: 'status', label: 'Status', values: [{ key: 'Active', label: 'Active' }] },
    { key: 'type', label: 'Type', values: [{ key: 'operator', label: 'Operator' }, { key: 'manufacturer', label: 'Manufacturer' }, { key: 'service provider', label: 'Service Provider' }] },
  ];
  const organizations = jsonData;

  const rows = useMemo(
    () =>
      organizations.map((org) => ({
        name: org.name,
        image: undefined,
        city: org.city,
        state: org.region,
        package: org.package.labels[0]?.value,
        status: org.status.labels[0]?.value,
        type: org.type.replace('_', ' ').toLowerCase(),
      })),
    [organizations]
  );

  const filteredRows = useMemo(
    () => {
      let filtered = search ? rows.filter(row => Object.values(row).some((itemValue) => itemValue?.includes(search))) : rows;
      filtered = Object.entries(filters)?.length ? rows.filter(row => Object.entries(filters).every(([filterKey, filterValue]) => row && row[filterKey as keyof typeof row]?.includes(filterValue))) : filtered;
      return filtered;
    },
    [filters, rows, search]
  );

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={true}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate('/')}>
                  <BackArrowIcon />
                </button>
                <h1>Organizations</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 0.5,
                minWidth: 326,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <OrganizationsIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
              },
              {
                field: 'city',
                headerName: 'City',
                width: 178,
                filterName: 'category_id',
              },
              {
                field: 'state',
                headerName: 'State',
                width: 145,
                filterName: 'category_classification_id',
              },
              {
                field: 'package',
                headerName: 'Package',
                width: 163,
                filterName: 'organization_id',
              },
              {
                field: 'status',
                headerName: 'Status',
                width: 100,
              },
              {
                field: 'type',
                headerName: 'Type',
                width: 179,
                filterName: 'type',
                cellRenderer: (params: { value: string }) => <div className={styles.typeField}>{params.value}</div>,
              },
              {
                sortable: false,
                field: 'actions',
                headerName: '',
                width: 179,
                cellRenderer: () => <ViewDetailsButton label="Access" onClick={() => setShowPopUp(true)} />,
              },
            ]}
            filters={filters}
            rows={filteredRows}
            rowHeight={116}
            availableFilters={availableFilters}
          />
          {showPopUp && <div />}
        </div>
      </div>
    </div>
  );
};
