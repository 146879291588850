import styles from './styles.module.css';

export const Loader = ({ isCentered = false }: { isCentered?: boolean }) => {
  return (
    <div className={`${styles.ellipsisContainer} ${isCentered ? styles.ellipsisCentered : ''}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};
