import { FormEvent, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { apiClient } from '../../../../api/apiClient';
import { ReactComponent as BackArrowIcon } from '../../../../assets/icons/back-arrow.svg';
import { ReactComponent as CameraIcon } from '../../../../assets/icons/camera.svg';
import styles from './styles.module.css';

export const PhotoStep = () => {
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [authError, setAuthError] = useState('');
  const navigate = useNavigate();

  const photoPreviewUrl = useMemo(() => (
    photoFile ? URL.createObjectURL(photoFile) : localStorage.getItem('auth.photo.photoFileUrl')
  ), [photoFile]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setAuthError('');

    const form = new FormData();
    photoFile && form.append('files', photoFile);
    apiClient.post('/upload', form)
      .then((res) => {
        const photoFileId = res.data?.[0]?.id;
        const photoFileUrl = res.data?.[0]?.url;
        photoFileId && localStorage.setItem('auth.photo.photoFileId', photoFileId);
        photoFileUrl && localStorage.setItem('auth.photo.photoFileUrl', `https://dev-front.hyvefyve.net${photoFileUrl}`);
        navigate('/sign-up/email');
      })
      .catch(err => {
        setAuthError(err.response?.data?.error?.message || "Photo upload failed");
      });
  };

  const goBack = () => {
    navigate('/sign-up');
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h2>Sign Up</h2>
      <p>Upload your photo</p>
      <Link to="/sign-up/email">Skip</Link>
      <div className={styles.upload}>
        <CameraIcon />
        {photoPreviewUrl && (
          <img src={photoPreviewUrl} alt="" />
        )}
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setPhotoFile(e.target.files?.[0] || null)}
          required
        />
      </div>
      <p className={styles.errorMessage}>{authError}</p>
      <div className={styles.controls}>
        <button type="button" className={styles.backBtn} onClick={goBack}>
          <BackArrowIcon />
        </button>
        <button className={styles.submitBtn} type="submit" disabled={!photoFile}>
          Next
        </button>
      </div>
    </form>
  );
};
