import { useEffect, useState } from 'react';
import { ButtonCircular } from "../ButtonCircular";
import { ReactComponent as SearchIcon } from "../../assets/icons/header/search.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/header/notification.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/menus/profile.svg";
import { apiClient } from "../../api/apiClient";
import styles from './styles.module.css';

export const Header = () => {
  const [userData, setUserData] = useState<{ firstName: string }>();

  useEffect(() => {
    const init = async () => {
      const res = await apiClient.get('/users-permissions/auth/verifyUser');
      setUserData(res?.data);
    };
    init();
  }, []);

  return (
    <header className={styles.container}>
      <ButtonCircular><SearchIcon /></ButtonCircular>
      <div className={styles.rightActions}>
        <ButtonCircular><span className={styles.lang}>EN</span></ButtonCircular>
        <ButtonCircular><NotificationIcon /></ButtonCircular>
        <div className={styles.userDropdown}>
          <ButtonCircular isDisabled isDark width={45} height={45}><ProfileIcon /></ButtonCircular>
          <div className={styles.userInfo}>
            <p className={styles.userName}>{userData?.firstName || ' '}</p>
            <p className={styles.userType}>Social Profile</p>
          </div>
        </div>
      </div>
    </header>
  );
};
