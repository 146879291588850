import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from "../../contexts/AuthContext";

export const PublicOnlyRoute = ({ children }: { children?: React.JSX.Element }) => {
  const { accessToken } = useAuthContext();

  if (accessToken) {
    return <Navigate to='/' replace />;
  }

  return children ? children : <Outlet />;
};
