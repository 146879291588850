import React from 'react';
import styles from './styles.module.css';

interface ICardProps {
  children: React.ReactNode;
  className?: string;
}

export const Card = ({ children, className = '' }: ICardProps) => (
  <div className={`${styles.container} ${className}`}>
    {children}
  </div>
);
