import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success.svg';
import styles from './styles.module.css';

export const SuccessStep = () => {
  const navigate = useNavigate();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h2>Verified!</h2>
      <p>You have successfully verified your email and phone.</p>
      <SuccessIcon className={styles.icon} />
      <div className={styles.controls}>
        <button className={styles.submitBtn} type="submit">
          Next
        </button>
      </div>
    </form>
  );
};
