import { ApolloProvider } from '@apollo/client';
import { apolloClient } from "./api/apolloClient";
import { AuthProvider } from "./contexts/AuthContext";
import { AppRouter } from "./routers/AppRouter";

export const App = () => (
  <ApolloProvider client={apolloClient}>
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  </ApolloProvider>
);
