import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
// import ManageColumnsController from './ManageColumnsController';
import './index.css';
import { Modal } from "../Modal";
import { Select } from "../Select";

const Table = ({
  isLoading,
  columns,
  rows,
  rowHeight = 78,
  onChangeSearch,
  onChangeFilters,
  search,
  filters,
  header,
  availableFilters,
}) => {
  const gridRef = useRef();
  const [openFilters, setOpenFilters] = useState(false);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    []
  );

  useEffect(() => {
    if (isLoading) {
      gridRef.current.api?.showLoadingOverlay();
    }
  }, [isLoading]);

  const cellClickedListener = useCallback((event) => {
    console.log('cellClicked', event);
  }, []);

  // const filterableCols = useMemo(() => columns.filter((col) => col.filters), [columns]);

  return (
    <div style={{ flex: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginTop: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          {header}
          <button className="filtersBtn" onClick={() => setOpenFilters(true)}>Filter</button>
        </div>
        <input
          className="search"
          value={search}
          onChange={(e) => onChangeSearch(e.target.value)}
          placeholder="Search"
        />
      </div>
      <div
        style={{ flex: 1, marginTop: '18px' }}
        className="ag-gr-wrapper ag-theme-alpine"
      >
        <AgGridReact
          ref={gridRef}
          rowData={rows}
          onGridReady={() => !rows?.length && gridRef.current.api?.showLoadingOverlay()}
          loadingOverlayComponent={() => <p>Loading</p>}
          rowHeight={rowHeight}
          headerHeight={65}
          columnDefs={columns}
          defaultColDef={defaultColDef}
          animateRows
          onCellClicked={cellClickedListener}
          scrollbarWidth={0}
        />
      </div>
      {openFilters && (
        <Modal onClose={() => setOpenFilters(false)}>
          <div className="filters">
            <h1>Filters</h1>
            <div>
              {availableFilters.map((filter) => (
                <Select
                  label={filter.label}
                  value={filters[filter.key]}
                  placeholder={`Select ${filter.label.toLowerCase()}`}
                  options={filter.values.map(val => ({ key: val.key, label: val.label }))}
                  onChange={(newValue) => {
                    onChangeFilters(prevFilters => ({ ...prevFilters, [filter.key]: newValue }));
                  }}
                />
              ))}
            </div>
            <button className="applyBtn" onClick={() => setOpenFilters(false)}>Apply</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Table;
