import React from 'react';
import AuthCode from 'react-auth-code-input';
import styles from './styles.module.css';

interface ICodeInputProps {
  onChange: (value: string) => void;
  autoFocus?: boolean;
}

export const CodeInput = ({ onChange, autoFocus }: ICodeInputProps) => (
  <AuthCode onChange={onChange} placeholder=" " autoFocus={autoFocus} containerClassName={styles.container} inputClassName={styles.inputContainer} />
);
