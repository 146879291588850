import React, { createContext, useContext, useState } from 'react';
import { apiClient } from "../../api/apiClient";

const ACCESS_TOKEN_KEY = 'hyve.access_token';
const REFRESH_TOKEN_KEY = 'hyve.refresh_token';

interface IAuthContext {
  accessToken: string | undefined;
  signInRequest: (isSms: boolean, identificator: string) => void;
  signInVerify: (isSms: boolean, identificator: string, code: string) => void;
  signOut: () => void;
}

const AuthContext = createContext<IAuthContext>({
  accessToken: undefined,
  signInRequest: () => {},
  signInVerify: () => {},
  signOut: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem(ACCESS_TOKEN_KEY) || undefined);

  const signInRequest = async (isSms: boolean, identificator: string) => {
    const url = isSms ? '/users-permissions/auth/sendPhoneCode' : '/users-permissions/auth/sendEmailCode';
    return await apiClient.post(url, isSms ? { phone: identificator } : { email: identificator });
  };

  const signInVerify = async (isSms: boolean, identificator: string, code: string) => {
    const url = isSms ? '/users-permissions/auth/verifyPhoneCode' : '/users-permissions/auth/verifyEmailCode';
    const res = await apiClient.post(
      url,
      isSms ? { phone: identificator, token: code } : { email: identificator, token: code },
    );
    const accessToken = res.data.accessToken;
    const refreshToken = res.data.refreshToken;
    apiClient.defaults.headers['Authorization'] = accessToken;
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    setAccessToken(accessToken);
  };

  const signOut = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    delete apiClient.defaults.headers['Authorization'];
    setAccessToken(undefined);
  };

  return (
    <AuthContext.Provider value={{
      accessToken,
      signInRequest,
      signInVerify,
      signOut,
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuthContext must be used within the AuthContext');
  }

  return context;
};
