import { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../../../../api/apiClient';
import styles from './styles.module.css';

export const PhoneStep = () => {
  const [phone, setPhone] = useState(localStorage.getItem('auth.phone.number') || '');
  const [authError, setAuthError] = useState('');
  const navigate = useNavigate();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setAuthError('');
    const jwt = localStorage.getItem('hyve.access_token');
    apiClient.post('/users-permissions/auth/signUpPhone', { jwt, phone })
      .then(() => {
        localStorage.setItem('auth.phone.number', phone);
        navigate('/sign-up/phone/verify');
      })
      .catch(err => {
        setAuthError(err.response?.data?.error?.message || "Phone verification failed");
      });
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h2>Sign Up</h2>
      <p>Enter your mobile phone number</p>
      <input
        value={phone}
        placeholder="Mobile Phone Number"
        onChange={(e) => setPhone(e.target.value)}
        type="tel"
        required
        autoFocus
      />
      <p className={styles.errorMessage}>{authError}</p>
      <div className={styles.controls}>
        <button className={styles.submitBtn} type="submit">
          Next
        </button>
      </div>
    </form>
  );
};
