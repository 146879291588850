import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { ReactComponent as ProfileIcon } from '../../assets/icons/menus/profile.svg';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import styles from './styles.module.css';
import coverImage from './cover.png';
import { GET_ORGANIZATION_DETAILS } from "./query";
import { Card } from "../../components/Card";
import { Loader } from "../../components/Loader";

export const OrganizationDetailsPage = () => {
  const { orgId } = useParams();

  const { data } = useQuery(GET_ORGANIZATION_DETAILS, {
    variables: {
      id: orgId,
    },
  });

  const orgDetails = data?.organization?.data?.attributes?.business_info?.data?.attributes;
  const locationsNumber = data?.organization?.data?.attributes?.managed_locations?.data?.length || 0;

  const renderDetails = () => (
    <>
      <div className={styles.profileHead}>
        <div className={styles.profileCover}>
          <img src={orgDetails.banner?.data?.attributes?.url || coverImage} alt='' />
        </div>
        <div className={styles.profileHeadRow}>
          <div className={styles.profileIcon}>
            {orgDetails.logo ? <img src={orgDetails.logo?.data?.attributes?.url} alt='' /> : <ProfileIcon />}
          </div>
          <div>
            <div className={styles.orgName}>{orgDetails.name}</div>
            <div className={styles.locationsNumber}>{locationsNumber} Locations</div>
          </div>
        </div>
      </div>
      <div className={styles.cards}>
        <div className={styles.leftColumn}>
          <Card className={styles.aboutCard}>
            <div className={styles.cardTitle}>
              About
            </div>
            <div className={styles.bio}>
              {orgDetails.about || <em>Bio is not available</em>}
            </div>
          </Card>
        </div>
        <div className={styles.rightColumn}>
          <Card>
            <div className={styles.cardTitle}>
              Working hours
            </div>
            <div className={styles.cardItemWithPadding}><em>Items not found</em></div>
          </Card>
          <Card>
            <div className={styles.cardTitle}>
              Contacts
            </div>
            <div className={styles.cardItemWithPadding}><em>Items not found</em></div>
          </Card>
        </div>
      </div>
      <div className={styles.bottomCards}>
        <Card>
          <div className={styles.cardTitle}>
            Locations
          </div>
          <div className={styles.cardItemWithPadding}><em>Items not found</em></div>
        </Card>
      </div>
    </>
  );

  return (
    <div className={styles.pageWrapper}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          {orgDetails ? renderDetails() : <Loader isCentered />}
        </div>
      </div>
    </div>
  );
};
