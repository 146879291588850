import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '../../assets/icons/logo.svg';
import { ReactComponent as LogoSmallIcon } from '../../assets/icons/logo-small.svg';
import { ReactComponent as CaretLeftIcon } from '../../assets/icons/caret-left.svg';
import { ReactComponent as ProfileIcon } from '../../assets/icons/menus/profile.svg';
import { ReactComponent as ProductModelsIcon } from '../../assets/icons/menus/product-models.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/menus/organizations.svg';
import { ReactComponent as SettingsIcon } from '../../assets/icons/menus/settings.svg';
import styles from './styles.module.css';

export const Sidebar = () => {
  const [isCollapsedFix, setIsCollapsedFix] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const renderMenuItem = ({ label, link, Icon }: { label: string; link: string; Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }) => (
    <NavLink
      className={({ isActive }) =>
        `${isActive ? styles.linkActive : styles.link} ${isCollapsed ? styles.linkCollapsed : ''}`
      }
      to={link}
    >
      <Icon className={isCollapsed ? styles.linkCollapsedIcon : styles.linkIcon} />
      {isCollapsed ? '' : label}
    </NavLink>
  );

  return (
    <div className={isCollapsed ? styles.containerCollapsed : styles.container} onMouseOver={() => isCollapsedFix && setIsCollapsed(false)} onMouseLeave={() => isCollapsedFix && setIsCollapsed(true)}>
      <div className={styles.topContainer}>
        {isCollapsed ? <LogoSmallIcon /> : <LogoIcon />}
        {isCollapsed ? null : <CaretLeftIcon className={isCollapsedFix ? styles.menuCollapsedIcon : styles.menuIcon} onClick={() => setIsCollapsedFix(!isCollapsedFix)} />}
      </div>
      <div className={isCollapsed ? styles.categoriesCollapsed : styles.categories}>
        <div className={isCollapsed ? '' : styles.category}>
          {isCollapsed ? null : <div className={styles.categoryTitle}>Organization</div>}
          {renderMenuItem({ label: 'Settings', link: '/organization/settings', Icon: SettingsIcon })}
        </div>
        <div className={isCollapsed ? '' : styles.category}>
          {isCollapsed ? null : <div className={styles.categoryTitle}>User</div>}
          {renderMenuItem({ label: 'Profile', link: '/profile', Icon: ProfileIcon })}
          {renderMenuItem({ label: 'Organizations', link: '/organizations', Icon: OrganizationsIcon })}
          {renderMenuItem({ label: 'Product Models', link: '/product-models', Icon: ProductModelsIcon })}
        </div>
      </div>
    </div>
  );
};
