import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../components/Table/Table';
import { ViewDetailsButton } from '../../components/Table/ViewDetailsButton';
import { Sidebar } from '../../components/Sidebar';
import { Header } from '../../components/Header';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import { ReactComponent as OrganizationsIcon } from '../../assets/icons/organizations-icon.svg';
import styles from './styles.module.css';
import jsonData from './data.json';

export const ProductModelsPage = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [search, setSearch] = useState('');
  const [showPopUp, setShowPopUp] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);

  const availableFilters = [
    { key: 'manufacturerName', label: 'Manufacturer', values: [{ key: 'Carrier', label: 'Carrier' }, { key: 'COOK', label: 'COOK' }, { key: 'DAYTON', label: 'DAYTON' }, { key: 'Halton', label: 'Halton' }] },
    { key: 'category', label: 'Category', values: [
        { key: 'Centerline Table, Cold', label: 'Centerline Table, Cold' },
        { key: 'Centerline Table, Hot', label: 'Centerline Table, Hot' },
        { key: 'Exhaust System', label: 'Exhaust System' },
        { key: 'Holding', label: 'Holding' },
        { key: 'HVAC', label: 'HVAC' },
        { key: 'Toaster, Bun', label: 'Toaster, Bun' },
        { key: 'Transfer Station/Stainless Cart', label: 'Transfer Station/Stainless Cart' },
        { key: 'Walk-in Cooler', label: 'Walk-in Cooler' },
        { key: 'Warmer', label: 'Warmer' },
    ]},
    { key: 'classification', label: 'Classification', values: [{ key: 'Cold Side', label: 'Cold Side' }, { key: 'Hot Side', label: 'Hot Side' }, { key: 'HVAC', label: 'HVAC' }, { key: 'Misc', label: 'Misc' }] },
  ];
  const productModels = jsonData;

  const rows = useMemo(
    () =>
      productModels.map((pm) => ({
        name: pm.name,
        image: undefined,
        category: pm.category.labels[0]?.value,
        classification: pm.category.classification.labels[0]?.value,
        manufacturerName: pm.organization.name,
      })),
    [productModels]
  );

  const filteredRows = useMemo(
    () => {
      let filtered = search ? rows.filter(row => Object.values(row).some((itemValue) => itemValue?.includes(search))) : rows;
      filtered = Object.entries(filters)?.length ? rows.filter(row => Object.entries(filters).every(([filterKey, filterValue]) => row && row[filterKey as keyof typeof row]?.includes(filterValue))) : filtered;
      return filtered;
    },
    [filters, rows, search]
  );

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div style={{ flex: 1 }}>
        <Header />
        <div className={styles.container}>
          <Table
            isLoading={true}
            onChangeSearch={setSearch}
            onChangeFilters={setFilters}
            search={search}
            header={(
              <div className={styles.titleContainer}>
                <button type="button" className={styles.backBtn} onClick={() => navigate('/')}>
                  <BackArrowIcon />
                </button>
                <h1>Product Models</h1>
              </div>
            )}
            columns={[
              {
                field: 'name',
                headerName: 'Name',
                flex: 0.5,
                minWidth: 326,
                cellRenderer: (params: { data: { image: string }; value: string }) => (
                  <div className={styles.nameField}>
                    {params.data.image ? <img className="product-image" src={params.data.image} alt="" /> : <OrganizationsIcon className="product-image" />}
                    <p className="product-name">{params.value}</p>
                  </div>
                ),
              },
              {
                field: 'category',
                headerName: 'Category',
                width: 260,
                filterName: 'category_id',
              },
              {
                field: 'classification',
                headerName: 'Classification',
                width: 160,
                filterName: 'category_classification_id',
              },
              {
                field: 'manufacturerName',
                headerName: 'Manufacturer',
                width: 360,
                filterName: 'manufacturerName',
              },
              {
                sortable: false,
                field: 'actions',
                headerName: '',
                width: 179,
                cellRenderer: () => <ViewDetailsButton label="View Details" onClick={() => setShowPopUp(true)} />,
              },
            ]}
            filters={filters}
            rows={filteredRows}
            rowHeight={116}
            availableFilters={availableFilters}
          />
          {showPopUp && <div />}
        </div>
      </div>
    </div>
  );
};
