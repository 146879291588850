import { gql } from '@apollo/client';

export const GET_ORGANIZATION_DETAILS = gql`
    query GetOrganizations($id: ID) {
        organization(id: $id) {
            data {
                id
                attributes {
                    business_info {
                        data {
                            id
                            attributes {
                                name
                                type
                                about
                                logo {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                banner {
                                    data {
                                        id
                                        attributes {
                                            url
                                        }
                                    }
                                }
                                address {
                                    data {
                                        id
                                        attributes {
                                            city
                                        }
                                    }
                                }
                            }
                        }
                    }
                    managed_locations(publicationState: LIVE) {
                        data {
                            id
                        }
                    }
                }
            }
        }
    }
`;
