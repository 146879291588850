import { Outlet } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import styles from './styles.module.css';

export const SignUpPage = () => (
  <div className={styles.container}>
    <div className={styles.card}>
      <div className={styles.cardContent}>
        <div className={styles.visuals}>
          <h1 className={styles.welcomeTitle}>Welcome to our community</h1>
          <p className={styles.welcomeSubtitle}>
            Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
          </p>
        </div>
        <div className={styles.content}>
          <div className={styles.contentHead}>
            <a href="https://hyvery.com" className={styles.logo}>
              <Logo />
            </a>
            <a href="https://hyve.help.center.hyvery.com">Need Help?</a>
          </div>
          <div className={styles.form}>
            <Outlet />
          </div>
          <div className={styles.contentLinks}>
            <a href="https://hyvery.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
            <a href="https://hyvery.com/terms-services" target="_blank" rel="noreferrer">Terms & Conditions</a>
          </div>
        </div>
      </div>
    </div>
  </div>
);
