import { ChangeEvent, FormEvent, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { CodeInput } from '../../components/CodeInput';
import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as BackArrowIcon } from '../../assets/icons/back-arrow.svg';
import styles from './styles.module.css';
import { useAuthContext } from "../../contexts/AuthContext";

export const SignInPage = ({ isSms = false }: { isSms?: boolean }) => {
  const [isCodeStep, setIsCodeStep] = useState(false);
  const [identificator, setIdentificator] = useState('');
  const [code, setCode] = useState('');
  const [authError, setAuthError] = useState('');
  const navigate = useNavigate();
  const { signInRequest, signInVerify } = useAuthContext();

  const onRequestCode = async () => {
    setAuthError('');
    try {
      await signInRequest(isSms, identificator);
      setIsCodeStep(true);
    } catch (err: any) {
      setAuthError(err.response?.data?.error?.message || "Authorization failed");
    }
  };

  const onVerifyCode = async (code: string) => {
    setAuthError('');
    try {
      await signInVerify(isSms, identificator, code);
      navigate('/');
    } catch (err: any) {
      setAuthError(err.response?.data?.error?.message || "Authorization failed");
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    isCodeStep ? onVerifyCode(code) : onRequestCode();
  };

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIdentificator(isSms ? value.replace(/[^0-9+()]/g, '') : value);
    setAuthError('');
  };

  const handleCodeChange = (value: string) => {
    setCode(value);
    setAuthError('');
    if (value.length === 6) {
      onVerifyCode(value);
    }
  };

  const goBack = () => {
    setIsCodeStep(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <div className={styles.visuals}>
            <h1 className={styles.welcomeTitle}>Welcome to our community</h1>
            <p className={styles.welcomeSubtitle}>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit.
            </p>
          </div>
          <div className={styles.content}>
            <div className={styles.contentHead}>
              <a href="https://hyvery.com" className={styles.logo}>
                <Logo />
              </a>
              <a href="https://hyve.help.center.hyvery.com">Need Help?</a>
            </div>
            <form className={styles.form} onSubmit={onSubmit}>
              <h2>Sign In</h2>
              <p>{isCodeStep ?
                (isSms ? `We just sent a code to ${identificator}. What is it?` : `We sent a confirmation link to ${identificator}. Please check it.`) :
                `Sign in with your ${isSms ? "mobile phone number" : 'email'}`}
              </p>
              {isCodeStep ? (
                <CodeInput onChange={handleCodeChange} autoFocus />
              ) : (
                <input
                  value={identificator}
                  placeholder={isSms ? "Mobile Phone Number" : "What is your work email address?"}
                  onChange={onChangeEmail}
                  type={isSms ? "tel" : "email"}
                  required
                  autoFocus
                />
              )}
              <p className={styles.errorMessage}>{authError}</p>
              <div className={styles.controls}>
                {isCodeStep ? (
                  <button type="button" className={styles.backBtn} onClick={goBack}>
                    <BackArrowIcon />
                  </button>
                ) : null}
                <button className={styles.submitBtn} type="submit" disabled={isCodeStep ? code.length !== 6 : !identificator}>
                  {isCodeStep ? 'Verify' : "Sign In"}
                </button>
              </div>
              <Link to={`/sign-in/${isSms ? 'email' : 'phone'}`}>
                Sign in via {isSms ? 'email' : "phone number"}
              </Link>
              {!isCodeStep ? (
                <p className={styles.signUp}>
                  Don't have an account yet?{' '}
                  <Link to="/sign-up">
                    Sign Up
                  </Link>
                </p>
              ) : null}
            </form>
            <div className={styles.contentLinks}>
              <a href="https://hyvery.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
              <a href="https://hyvery.com/terms-services" target="_blank" rel="noreferrer">Terms & Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
