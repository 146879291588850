import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export const NameStep = () => {
  const [firstName, setFirstName] = useState(localStorage.getItem('auth.name.firstName') || '');
  const [lastName, setLastName] = useState(localStorage.getItem('auth.name.lastName') || '');
  const [authError, setAuthError] = useState('');
  const navigate = useNavigate();

  const onSubmit = () => {
    setAuthError('');
    localStorage.setItem('auth.name.firstName', firstName);
    localStorage.setItem('auth.name.lastName', lastName);
    navigate('/sign-up/photo');
  };

  return (
    <form className={styles.form} onSubmit={onSubmit}>
      <h2>Sign Up</h2>
      <p>Let’s get you to your first work order!</p>
      <input
        value={firstName}
        placeholder="First name"
        onChange={(e) => setFirstName(e.target.value)}
        required
        autoFocus
      />
      <input
        value={lastName}
        placeholder="Last name"
        onChange={(e) => setLastName(e.target.value)}
        required
      />
      <p className={styles.errorMessage}>{authError}</p>
      <div className={styles.controls}>
        <button className={styles.submitBtn} type="submit" disabled={!firstName || !lastName}>
          Next
        </button>
      </div>
      <p className={styles.signIn}>
        Already have an account.{' '}
        <Link to="/sign-in/email">
          Sign In
        </Link>
      </p>
    </form>
  );
};
