import { RefObject, useEffect } from 'react';

export const useHandleOutsideMouseDown = (ref: RefObject<HTMLDivElement>, onClickOutside: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside && onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickOutside]);
};
